// App.js
import React, { useEffect, useRef } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import StartRegisterScreen from "./screens/StartRegister";
import RegisterScreen from "./screens/Register";
import LoginScreen from "./screens/Login";
import HomeScreen from "./screens/Home";
import MapSalesScreen from "./screens/MapSales";
import ImportDataScreen from "./screens/ImportData";
import ImportDataEditScreen from "./screens/ImportDataEdit";
import PromoScreen from "./screens/PromoScreen";
import "./index.css";
import Home from "./ezout-web/src/views/Home";
import InvestorNewsletter from "./ezout-web/src/views/InvestorNewsletter";
import { useLocation } from "react-router-dom";
import AppRoute from "./ezout-web/src/utils/AppRoute";
import ScrollReveal from "./ezout-web/src/utils/ScrollReveal";
import ReactGA from "react-ga";

// Layouts
import LayoutDefault from "./ezout-web/src/layouts/LayoutDefault";

// Initialize Google Analytics
ReactGA.initialize(process.env.REACT_APP_GA_CODE);

const trackPage = (page) => {
  ReactGA.set({ page });
  ReactGA.pageview(page);
};

const App = () => {
  const childRef = useRef();
  // let location = useLocation();

  // useEffect(() => {
  //   const page = location.pathname;
  //   document.body.classList.add("is-loaded");
  //   childRef.current.init();
  //   trackPage(page);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [location]);

  return (
    <ScrollReveal
      ref={childRef}
      children={() => (
        <Router>
          <div className="whole-screen">
            <Routes>
              <Route
                exact
                path="/"
                element={
                  <LayoutDefault>
                    <Home />
                  </LayoutDefault>
                }
              />
              <Route
                exact
                path="/investor-newsletter"
                element={<InvestorNewsletter />}
              />
              <Route exact path="/home" element={<HomeScreen />} />
              <Route exact path="/login" element={<LoginScreen />} />
              <Route
                exact
                path="/start-register"
                element={<StartRegisterScreen />}
              />
              <Route exact path="/register" element={<RegisterScreen />} />
              <Route path="/map-sales" element={<MapSalesScreen />} />
              <Route path="/import-data" element={<ImportDataScreen />} />
              <Route path="/edit-data" element={<ImportDataEditScreen />} />
              <Route path="/promotional-ads" element={<PromoScreen />} />
              {/* Add more routes as needed */}
            </Routes>
          </div>
        </Router>
      )}
    />
  );
};

export default App;
