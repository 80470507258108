// Home.js
import { Layout, Typography, Card, Button, Form, Input, Spin, Statistic, Descriptions } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../config/config-firebase";
import { onAuthStateChanged, signOut, RecaptchaVerifier, PhoneAuthProvider, updatePhoneNumber } from "firebase/auth";
import { jwtDecode } from "jwt-decode";
import Sidebar from "../components/sidebar";
import { FaPencilAlt } from 'react-icons/fa';

import globalStore from "../global/globalStore.js";
import { render } from "@testing-library/react";

const HomeScreen = () => {
  const [user, setUser] = useState(null);
  const [jwt, setJwt] = useState("");
  const [merchantDetails, setMerchantDetails] = useState(null);
  const [error, setError] = useState('');
  const [remainingTime, setRemainingTime] = useState(15 * 60); // Remaining time in seconds
  const navigate = useNavigate();
  const idleTimeoutRef = useRef(null);
  const countdownIntervalRef = useRef(null);

  const [merchantName, setMerchantName] = useState('');
  const [deliveryInstructions, setDeliveryInstructions] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [postcode, setPostcode] = useState('');
  const [responsibleName, setResponsibleName] = useState('');
  const [mobile, setMobile] = useState('');
  const [phone, setPhone] = useState('');

  const [editPhone, setEditPhone] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [showVerificationInput, setShowVerificationInput] = useState(false);
  const [newPhoneNumber, setNewPhoneNumber] = useState('');
  const [phoneNumberError, setPhoneNumberError] = useState('');

  const setupRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
      'size': 'invisible',
      'callback': (response) => {
      }
    });
  };

  const requestVerificationCode = async () => {
    setupRecaptcha();
    const phoneNumber = formatPhoneNumberToE164(newPhoneNumber);
    if (!phoneNumber) {
      setError("Invalid phone number format.");
      return;
    }

    try {
      const appVerifier = window.recaptchaVerifier;
      const provider = new PhoneAuthProvider(auth);
      const verificationId = await provider.verifyPhoneNumber(phoneNumber, appVerifier);
      // Prompt user for the verification code
      const verificationCode = window.prompt('Please enter the verification code sent to your mobile device.');

      if (!verificationCode) {
        setError("Verification code not provided.");
        return;
      }

      const phoneCredential = PhoneAuthProvider.credential(verificationId, verificationCode);
      await updatePhoneNumberForUser(phoneCredential);
    } catch (error) {
      console.error("Error during phone number verification:", error);
      setError(error.message);
    }
  };

  const updatePhoneNumberForUser = async (phoneCredential) => {
    if (auth.currentUser) {
      try {
        await updatePhoneNumber(auth.currentUser, phoneCredential); // Using modular approach for Firebase v9+
        console.log("Phone number updated successfully.");
        await updatePhoneNumberInBackend(formatPhoneNumberToE164(newPhoneNumber));
        cancelEditPhone();
      } catch (error) {
        console.error("Error updating phone number:", error);
        setError("Failed to update phone number: " + error.message);
      }
    } else {
      setError("No authenticated user.");
    }
  };

  const updatePhoneNumberInBackend = async (phoneNumber) => {
    try {
      const idToken = await auth.currentUser.getIdToken(true);
      const response = await fetch('http://localhost:8080/updateUserPhoneNumber', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(
            {
              uid: auth.currentUser.uid,
              newPhoneNumber: phoneNumber,
              token: idToken
            }),
      });

      if (!response.ok) {
        throw new Error("Failed to update phone number in backend.");
      }

      console.log("Phone number updated in backend successfully.");
      await fetchMerchantDetails(user.uid);
    } catch (error) {
      console.error("Backend update error:", error);
      setError("Failed to update phone number in backend.");
    }
  };


  const formatPhoneNumberToE164 = (number) => {
    const digits = number.replace(/\D/g, ''); // Strip all non-digits
    if (digits.startsWith('1')) {
      // Assumes '1' is the country code for US
      return `+${digits}`;
    } else {
      // Modify according to the country code you expect to use
      return `+1${digits}`;
    }
  };

  const startEditPhone = () => {
    setEditPhone(true);
    setPhoneNumberError('');
  };

  const cancelEditPhone = () => {
    setEditPhone(false);
    setNewPhoneNumber('');
    setShowVerificationInput(false);
    setVerificationCode('');
    setError('');
  };

  // Adding state for editing states
  const [editState, setEditState] = useState({
    name: false,
    email: false,
    mobile: false,
  });

  // Function to toggle edit state

  const toggleEditState = (field) => {
    setEditState((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  // Function to fetch merchant details
  const fetchMerchantDetails = async (uid) => {
    console.log("Fetching merchant details for UID:", uid);
    try {
      const response = await fetch(`http://localhost:8080/merchantDetails/${uid}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          // Include authorization header if there's further backend requires
          // 'Authorization': `Bearer ${jwt}`,
        },
      });
      if (!response.ok) {
        throw new Error(`Failed to fetch merchant details: ${response.statusText}`);
      }
      const data = await response.json();
      console.log("Fetched merchant details:", JSON.stringify(data, null, 2));
      setMerchantDetails(data.data);
      setEmail(data.data.email || '');
      setAddress(data.data.address_line || '');
      setCity(data.data.town_city || '');
      setPostcode(data.data.postcode || '');
      setResponsibleName(data.data.name || '');
      setMobile(formatPhoneNumber(data.data.mobile || ''));
      setPhone(formatPhoneNumber(data.data.phone || ''));
      setMerchantName(data.data.full_name || '');
      setDeliveryInstructions(data.data.delivery_instructions || '');
    } catch (error) {
      console.error("Error fetching merchant details:", error);
      setError(error.message);
    }
  };


  // Function to update merchant details
  const handleUpdate = async (e, fieldType) => {
    e.preventDefault();

    let updateData = {};
    switch (fieldType) {
      case 'email':
        updateData = { responsiblePerson: { email } };
        break;
      case 'mobile':
        updateData = { responsiblePerson: { mobile } };
        break;
      case 'name':
        updateData = { responsiblePerson: { name: responsibleName } };
        break;
      default:
        break;
    }

    try {
      const response = await fetch(`http://localhost:8080/updateMerchantDetails/${user.uid}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updateData),
      });

      if (!response.ok) {
        throw new Error('Failed to update information');
      }

      // Success handling here
      console.log("Information updated successfully");
      await fetchMerchantDetails(user.uid);
      toggleEditState(fieldType); // Turn off edit mode for the field

    } catch (error) {
      console.error("Error updating information:", error);
      setError(error.message || 'Failed to update information');
    }
  };


  function formatPhoneNumber(phoneNumber) {
    console.log('Formatting phoneNumber:', phoneNumber);
    // Remove any non-digit characters (including + sign)
    const digits = phoneNumber.replace(/\D/g, '');

    if (digits.length === 11 && digits.startsWith('1')) {
      // Assuming the country code is '1' for US numbers
      const localNumber = digits.slice(1); // Remove the country code
      return `${localNumber.slice(0, 3)}-${localNumber.slice(3, 6)}-${localNumber.slice(6)}`;
    } else {
      console.error('Unexpected phone number format:', phoneNumber);
      return phoneNumber; // Return original if it doesn't match expected format
    }
  }


  const handleSignOut = async () => {
    try {
      await signOut(auth);
      console.log("User signed out");
      navigate("/"); // Navigate to home
      window.location.reload();
    } catch (error) {
      console.error("Sign out error", error);
    }
  };

  const resetIdleTimer = () => {
    clearTimeout(idleTimeoutRef.current);
    clearInterval(countdownIntervalRef.current);
    setRemainingTime(15 * 60); // Reset remaining time to 15 minutes

    idleTimeoutRef.current = setTimeout(() => {
      alert("You have been logged out due to inactivity");
      handleSignOut();
    }, 15 * 60 * 1000); // 15 minutes

    countdownIntervalRef.current = setInterval(() => {
      setRemainingTime((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(countdownIntervalRef.current); // Stop the countdown
          handleSignOut(); // Automatically sign out when the time is up
        }
        return prevTime - 1;
      });
    }, 1000); // Update remaining time every second
  };

  useEffect(() => {
    document.addEventListener("mousemove", resetIdleTimer);
    document.addEventListener("keydown", resetIdleTimer);

    return () => {
      document.removeEventListener("mousemove", resetIdleTimer);
      document.removeEventListener("keydown", resetIdleTimer);
      clearTimeout(idleTimeoutRef.current);
      clearInterval(countdownIntervalRef.current);
    };
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        const idToken = await currentUser.getIdToken(true);
        console.log("Authenticated UID:", currentUser.uid); // Log the UID of the authenticated user
        setUser(currentUser);
        console.log(currentUser.uid);
        setJwt(idToken);


        let jwtTemp = jwtDecode(idToken);

        // for data retrieval
        const API_URL = "http://localhost:8080/getCustomerId";

        let params = jwtTemp.user_id.toString();

        resetIdleTimer();
        fetchMerchantDetails(currentUser.uid); // Fetch merchant details

        try {
          const response = await fetch(API_URL, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ userId: params }),
          });

          const responseData = await response.json();
          if (response.ok) {
            globalStore.customerId = responseData.data.customer_id;
            localStorage.setItem("customer_id",responseData.data.customer_id)
          } else {
            console.error("Get user info failed:", responseData);
          }
        } catch (error) {
          console.error("Error submitting registration data:", error);
        }

        console.log("globalStore", globalStore);


      } else {
        // Redirect to login if there is no user
        setUser(null);
        setJwt("");
        navigate("/");
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  // Convert remaining time to minutes and seconds for display
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  // Conditional rendering based on user authentication
  if (!user) {
    return (
        <div className="home-container">
          <h2 className="title">Home Screen</h2>
          <div className="button-group">
            <button onClick={() => navigate('/login')} className="btn login">Log In</button>
            <button onClick={() => navigate('/register')} className="btn register">Register</button>
          </div>
        </div>
    );
  }

  return (
      <div style={styles.screenContainer}>
        <Sidebar />
        <div id="recaptcha-container"></div>
        <div style={styles.screenContent}>
          <div style={styles.contentCenter}>
            <Typography.Title level={1} style={{ fontSize: '2.5em', textAlign: 'center' }}>Welcome, {responsibleName}!</Typography.Title>
            <Statistic
                title="Time until auto logout for inactivity"
                value={formatTime(remainingTime)}
                style={{ fontSize: '1.2em', textAlign: 'center', marginBottom: '20px' }}
            />
            {merchantDetails ? (
                <>
                  <Card title={<span style={{ fontSize: '1.3em' }}>Merchant Information</span>} style={styles.card}>
                    <Descriptions column={1} style={{ fontSize: '1.1em' }}>
                      <Descriptions.Item label="Merchant Name" labelStyle={styles.descriptionLabel} contentStyle={styles.descriptionContent}>{merchantDetails.full_name}</Descriptions.Item>
                      <Descriptions.Item label="Merchant Phone" labelStyle={styles.descriptionLabel} contentStyle={styles.descriptionContent}>{phone}</Descriptions.Item>
                      <Descriptions.Item label="Merchant Address" labelStyle={styles.descriptionLabel} contentStyle={styles.descriptionContent}>
                        {merchantDetails.address_line}, {merchantDetails.town_city}
                      </Descriptions.Item>
                      <Descriptions.Item label="Merchant Postcode" labelStyle={styles.descriptionLabel} contentStyle={styles.descriptionContent}>{merchantDetails.postcode}</Descriptions.Item>
                    </Descriptions>
                  </Card>

                  <Card title={<span style={{ fontSize: '1.3em' }}>User Information</span>} style={styles.card}>
                    <Descriptions column={1} style={{ fontSize: '1.1em' }}>
                      <Descriptions.Item
                          label="Name"
                          labelStyle={styles.descriptionLabel}
                          contentStyle={styles.descriptionContent}
                      >
                        {editState.name ? (
                            <Form
                                layout="inline"
                                onFinish={(values) => handleUpdate({ preventDefault: () => {} }, 'name')}
                            >
                              <Form.Item name="name" initialValue={responsibleName}>
                                <Input
                                    onChange={(e) => setResponsibleName(e.target.value)}
                                    style={{ fontSize: '1em' }}
                                />
                              </Form.Item>
                              <Form.Item>
                                <Button type="primary" htmlType="submit" style={{ fontSize: '1em' }}>Save</Button>
                              </Form.Item>
                            </Form>
                        ) : (
                            <>
                              {responsibleName}
                              <EditOutlined onClick={() => toggleEditState('name')} style={{ cursor: 'pointer', marginLeft: '10px' }} />
                            </>
                        )}
                      </Descriptions.Item>

                      <Descriptions.Item
                          label="Email"
                          labelStyle={styles.descriptionLabel}
                          contentStyle={styles.descriptionContent}
                      >
                        {editState.email ? (
                            <Form
                                layout="inline"
                                onFinish={(values) => handleUpdate({ preventDefault: () => {} }, 'email')}
                            >
                              <Form.Item name="email" initialValue={email}>
                                <Input
                                    type="email"
                                    onChange={(e) => setEmail(e.target.value)}
                                    style={{ fontSize: '1em' }}
                                />
                              </Form.Item>
                              <Form.Item>
                                <Button type="primary" htmlType="submit" style={{ fontSize: '1em' }}>Save</Button>
                              </Form.Item>
                            </Form>
                        ) : (
                            <>
                              {email}
                              <EditOutlined onClick={() => toggleEditState('email')} style={{ cursor: 'pointer', marginLeft: '10px' }} />
                            </>
                        )}
                      </Descriptions.Item>

                      <Descriptions.Item
                          label="Phone"
                          labelStyle={styles.descriptionLabel}
                          contentStyle={styles.descriptionContent}
                      >
                        {editPhone ? (
                            <Form layout="inline">
                              <Form.Item>
                                <Input
                                    type="tel"
                                    placeholder="Enter new phone number"
                                    value={newPhoneNumber}
                                    onChange={(e) => setNewPhoneNumber(e.target.value)}
                                    style={{ fontSize: '1em' }}
                                />
                              </Form.Item>
                              <Form.Item>
                                <Button onClick={requestVerificationCode} type="primary" style={{ fontSize: '1em' }}>Update Phone Number</Button>
                              </Form.Item>
                              <Form.Item>
                                <Button onClick={cancelEditPhone} style={{ fontSize: '1em' }}>Cancel</Button>
                              </Form.Item>
                              {showVerificationInput && (
                                  <>
                                    <input
                                        type="text"
                                        placeholder="Verification Code"
                                        value={verificationCode}
                                        onChange={(e) => setVerificationCode(e.target.value)}
                                    />
                                    <button onClick={requestVerificationCode}>Validate Code</button>
                                  </>
                              )}
                            </Form>
                        ) : (
                            <>
                              {mobile}
                              <EditOutlined onClick={startEditPhone} style={{ cursor: 'pointer', marginLeft: '10px' }} />
                            </>
                        )}
                      </Descriptions.Item>
                    </Descriptions>
                  </Card>
                </>
            ) : (
                <Spin size="large" />
            )}
            {error && <Typography.Text type="danger" style={{ fontSize: '1.1em' }}>{error}</Typography.Text>}
          </div>
        </div>
      </div>
  );
};

const styles = {
  screenContainer: {
    display: "flex",
  },
  screenContent: {
    marginLeft: "250px",
    padding: "20px",
    flex: 1,
    display: "flex",
    justifyContent: "center",
  },
  contentCenter: {
    width: "80%",
    maxWidth: "1200px",
  },
  card: {
    marginTop: 16,
    width: '100%',
  },
  title: {
    marginBottom: "20px",
    color: "#227722",
  },
  button: {
    backgroundColor: "#338833",
    color: "white",
    border: "none",
    padding: "10px 20px",
    borderRadius: "5px",
    marginTop: "10px",
    cursor: "pointer",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
  },
  label: {
    textAlign: "left",
    width: "100%",
    margin: "10px 0",
  },
  input: {
    margin: "10px 0",
    padding: "10px",
    borderRadius: "5px",
    border: "1px solid #ccc",
    width: "95%",
  },
  descriptionLabel: {
    fontWeight: 'bold',
    textAlign: 'right',
    paddingRight: '20px',
    width: '30%',
  },
  descriptionContent: {
    textAlign: 'left',
    width: '70%',
  },
};

export default HomeScreen;