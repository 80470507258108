import React from "react";
import Sidebar from "../components/sidebar";
import NewPromo from "../PromoData/NewPromo";

const PromoScreen = () => {
  return (
    <div style={styles.screenContainer}>
      <Sidebar />
      <div style={styles.screenContent}>
        <NewPromo />
        {/* Additional Import Data content goes here */}
      </div>
    </div>
  );
};

const styles = {
  screenContainer: {
    display: "flex",
  },
  screenContent: {
    marginLeft: "250px",
    padding: "20px",
    flex: 1,
  },
};

export default PromoScreen;
