import axios from "axios";

import config from "../config/config.json";

const serverAddress = config.serverAddress;
const serverPort = config.serverPort;
const apiBaseUrl = `${serverAddress}`;

const customerId = localStorage.getItem("customer_id");

export const addPromo = async (promoData, file) => {
  const formData = new FormData();
  formData.append("title", promoData.title);
  formData.append("description", promoData.description);
  formData.append("customerId", customerId);
  if (file) {
    formData.append("adImage", file);
  }
  try {
    const response = await axios.post(
      `${apiBaseUrl}/uploadDigitalAd`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error("Error adding promo:", error);
    // throw error;
  }
};

export const findAll = async () => {
  const response = await axios.post(`${apiBaseUrl}/promotionalads`, {});
  return response.data;
};

export const editPromoCall = async (promo, file) => {
  const formData = new FormData();
  formData.append("title", promo.title);
  formData.append("description", promo.description);
  formData.append("customerId", customerId);
  formData.append("_id", promo._id);
  if (file) {
    formData.append("adImage", file);
  }
  const response = await axios.post(
    `${apiBaseUrl}/promotionalads/edit`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response.data;
};

export const deletePromoCall = async (promo) => {
  console.log(promo);
  const response = await axios.post(
    `${apiBaseUrl}/promotionalads/delete`,
    promo
  );
  return response.data;
};

// export const account = async () => {
//     try {
//         const response = await axios.post(`${apiBaseUrl}/uploadDigitalAd`, formData, {
//             headers: {
//                 'Content-Type': 'multipart/form-data'
//             }
//         });
//         console.log(`${apiBaseUrl}/uploadDigitalAd`);
//         return response.data;
//     } catch (error) {
//         console.error('Error adding promo:', error);
//         // throw error;
//     }
// }
