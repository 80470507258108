import React, { useState, useEffect } from "react";
import {
  addPromo,
  findAll,
  editPromoCall,
  deletePromoCall,
} from "../PromoData/promoService";
import { Table, Row } from "../components/Table/Table";
import {
  Form,
  Upload,
  Input,
  Popconfirm,
  Typography,
  Modal,
  Button,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";

import labels from "./Promo.labels.json";

const NewPromo = () => {
  const [form] = Form.useForm();
  const [promos, setPromos] = useState([]);
  const [openModal, setOpenModel] = useState(false);
  const [selected, setSelected] = useState({});
  const [fileList, setFileList] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const customerId = localStorage.getItem("customer_id");

  const fetchData = async () => {
    const data = await findAll();
    // console.log("fetch data", data, customerId);
    // const filteredData = data.filter((item) => item.customerId === customerId);
    // const formattedData = filteredData.map((item) => ({
    //   ...item,
    //   img: item.S3link,
    // }));
    // console.log("formattedData", formattedData);
    setPromos(data);
  };

  const handleAdd = () => {
    setModalTitle(labels.addPromo);
    setOpenModel(true);
    setSelected([]);
  };

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handlePreview = async (file) => {
    if (!file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const handleImageEdit = async ({ fileList: newFileList }) => {
    let newSelected = { ...selected };
    if (newFileList.length > 0) {
      newSelected.img = await getBase64(newFileList[0].originFileObj);
    } else {
      newSelected.img = "";
    }
    setFileList(newFileList);
    setSelected(newSelected);
  };

  const handleCancel = () => setPreviewOpen(false);

  const handleAddFunction = () => {
    form
      .validateFields()
      .then(async (values) => {
        console.log("fileList", fileList);
        const file = fileList.length ? fileList[0].originFileObj : null;
        const imageBase64 = file ? await getBase64(file) : null;

        // Prepare the data object to send to the backend, including the image as a base64 string
        const promoData = {
          ...values,
          title: selected.title,
          description: selected.description,
          customerId: global.customerId, // Assuming customerId is globally available
        };

        // API call to add the promo
        const AdData = await addPromo(promoData, fileList[0].originFileObj);
        setPromos((promos) => [...promos, { ...AdData, img: AdData.S3link }]);
        form.resetFields();
        setOpenModel(false);
        setSelected([]);
      })
      .catch((info) => {
        console.error("Error", info);
      });
  };

  const editPromo = (promo) => {
    setModalTitle(labels.editPromo);
    setSelected(promo);
    setOpenModel(true);
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <>
      <div>
        <Button
          onClick={handleAdd}
          type="primary"
          style={{
            marginBottom: 16,
          }}
        >
          {labels.addPromo}
        </Button>
        <Table cols={["Title", "Description", "Img", "Operation", "Operation"]}>
          {promos.map((promo, index) => (
            <Row key={index}>
              <Typography.Text>{promo.title}</Typography.Text>
              <Typography.Text>{promo.description}</Typography.Text>
              <img src={promo.S3link} alt="img" style={{ width: "100px" }} />
              <Button type="link" onClick={() => editPromo(promo)}>
                {labels.edit}
              </Button>
              <Popconfirm
                title="Sure to delete?"
                onConfirm={async () => {
                  await deletePromoCall(promo);
                  setPromos((promos) =>
                    promos.filter((item) => item._id !== promo._id)
                  );
                }}
              >
                <Button type="link">{labels.delete}</Button>
              </Popconfirm>
            </Row>
          ))}
        </Table>
        <Modal
          title={modalTitle}
          centered
          open={openModal}
          onOk={() => {
            setOpenModel(false);
            if (modalTitle === labels.editPromo) {
              let change;
              promos.forEach((promo) => {
                if (promo._id === selected._id) {
                  change =
                    JSON.stringify(promo) === JSON.stringify(selected)
                      ? false
                      : true;
                }
              });
              if (change) {
                editPromoCall(
                  selected,
                  fileList[0]?.originFileObj ? fileList[0].originFileObj : null
                );
                setPromos((promos) =>
                  promos.map((promo) => {
                    if (promo._id === selected._id) {
                      return selected;
                    }
                    return promo;
                  })
                );
              }
              return;
            }
            handleAddFunction();
          }}
          onCancel={() => setOpenModel(false)}
        >
          <>
            <Form
              labelCol={{
                span: 4,
              }}
              wrapperCol={{
                span: 14,
              }}
              layout="horizontal"
              disabled={false}
              style={{
                maxWidth: 600,
              }}
            >
              <Form.Item label={labels.title}>
                <Input
                  value={selected.title}
                  onChange={(e) => {
                    setSelected({
                      ...selected,
                      title: e.target.value,
                    });
                  }}
                />
              </Form.Item>
              <Form.Item label={labels.description}>
                <Input.TextArea
                  rows={4}
                  value={selected.description}
                  onChange={(e) =>
                    setSelected({
                      ...selected,
                      description: e.target.value,
                    })
                  }
                />
              </Form.Item>
              <Form.Item label={labels.img} name="image">
                <Upload
                  listType="picture-card"
                  fileList={fileList}
                  onPreview={handlePreview}
                  onChange={handleImageEdit}
                >
                  {fileList.length >= 1 ? null : selected.img ? (
                    <img
                      src={selected.img}
                      alt="img"
                      style={{ width: "100px" }}
                    />
                  ) : (
                    <div>
                      <PlusOutlined />
                      <div
                        style={{
                          marginTop: 8,
                        }}
                      >
                        {labels.upload}
                      </div>
                    </div>
                  )}
                </Upload>
                <Modal
                  open={previewOpen}
                  title={previewTitle}
                  footer={null}
                  onCancel={handleCancel}
                >
                  <img
                    alt="example"
                    style={{
                      width: "100%",
                    }}
                    src={previewImage}
                  />
                </Modal>
              </Form.Item>
            </Form>
          </>
        </Modal>
      </div>
    </>
  );
};

export default NewPromo;
