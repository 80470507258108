import React from "react";
import "./Table.css";

const Table = ({ cols, children }) => {
  return (
    <div className="tableContainer">
      <table
        style={{
          width: "100%",
        }}
      >
        <tbody>
          <tr>
            {cols.map((val, index) => (
              <th key={index}>{val}</th>
            ))}
          </tr>
          {children}
        </tbody>
      </table>
    </div>
  );
};

const Row = ({ children }) => {
  return (
    <tr className="rowStyle">
      {React.Children.map(children, (child, index) => {
        return <td key={index}>{child}</td>;
      })}
    </tr>
  );
};

export { Table, Row };
