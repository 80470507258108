import React from 'react';
import { Link } from 'react-router-dom';
import home from '../images/home.png';
import graph from '../images/bar-chart.png';
import map from '../images/map.png';
import importIcon from '../images/import.png';
import logout from '../images/logout.png';
import '../index.css';
import { useNavigate } from 'react-router-dom';

import { auth } from '../config/config-firebase';
import { signOut } from "firebase/auth";
import "./sidebar.css";


const Sidebar = () => {

  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await signOut(auth);
      console.log('User signed out');
      navigate('/'); // Navigate to home or login page after logout
    } catch (error) {
      console.error('Sign out error', error);
      // Optionally, handle logout error (e.g., show a message to the user)
    }
  };

  return (
    <div className="sidebar SidebarWrapper">
      <h2 className="sidebar-title">Executive Dashboard</h2>
      <ul className="my-custom-list">
        <li className="sidebar-element">
          <img src={home} alt="Home Icon" className="sidebar-icon" />
          <Link to="/">Home</Link>
        </li>
        <li className="sidebar-element">
          <img src={graph} alt="Sales Analytics Icon" className="sidebar-icon" />
          <Link to="/">Sales Analytics</Link>
        </li>
        <li className="sidebar-element">
          <img src={map} alt="Map Icon" className="sidebar-icon" />
          <Link to="/map-sales">Map of Store Sales</Link>
        </li>
        <li className="sidebar-element">
          <img src={importIcon} alt="Import Icon" className="sidebar-icon" />
          <Link to="/import-data">Import Inventory Data</Link>
        </li>
        <li className="sidebar-element">
          <img src={importIcon} alt="Import Icon" className="sidebar-icon" />
          <Link to="/edit-data">View Items</Link>
        </li>
        <li className="sidebar-element">
          <img src={graph} alt="Sales Analytics Icon" className="sidebar-icon" />
          <Link to="/promotional-ads">Promotional Ads</Link>
        </li>
      </ul>
      <div onClick={handleLogout} className="sidebar-bottom-logout">
        <img src={logout} alt="Logout Icon" style={{ width: 30, height: 30 }} className="logout-icon" />
        <p className="logout-text">logout</p>
      </div>
    </div>
  );
};

export default Sidebar;
